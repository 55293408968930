/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../application/cookie-consent'
require('@rails/ujs').start()
require('data-confirm-modal')
// use expose-loader with inline syntax to avoid duplication/overriding global variables
// when loading 2 different packs on the same page
import $ from 'expose-loader?exposes=$!jquery'
import 'jquery-ui/ui/effect'
import 'jquery-ui/ui/effects/effect-blind'
import 'jquery-ui/ui/effects/effect-fade'
import 'jquery-ui/ui/widgets/datepicker'
import 'jquery-ui/ui/widgets/progressbar'
import 'jquery.bcSwipe'
import 'jquery-cookie/jquery.cookie'

// import bootstrap components in order to customise modal
import 'bootstrap/js/transition'
import 'bootstrap/js/alert'
import 'bootstrap/js/button'
import 'bootstrap/js/carousel'
import 'bootstrap/js/collapse'
import 'bootstrap/js/dropdown'
import '../bootstrap/modal'
import 'bootstrap/js/tooltip'
import 'bootstrap/js/popover'
import 'bootstrap/js/scrollspy'
import 'bootstrap/js/tab'
import 'bootstrap/js/affix'

import '../application/analytics'
import '../application/analytics_funnel'
import '../application/modernizr'
import '../modernizr/tests/hdpi'
import '../application/sift'
import '../application/payment_methods'
import '../application/orders'
import '../products/ajax-events'
import '../products/product'
import '../products/search'
import '../products/quick-preview'
import '../products/spinner-preview'
import '../products/sprite-spinner-preview'
import '../products/gallery-item'
import '../products/pixelsquid-quick-preview'
import '../products/lightbox'
import '../products/user_lightbox'
import '../plugin_demo/plugin-demo'
import '../application/nav'
import '../application/hello_banner'
import '../application/login'
import '../application/user'
import '../application/free_products_lightboxes'
import '../application/pricing'
import '../application/stripe_elements'
import '../application/data-confirm-modal-defaults.js'
import '../application/newrelic'
